.x-environment-badge {
  display: inline-block;
  border-radius: 3px;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  font-variant: small-caps;
  padding: 3px 8px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  user-select: none;
}

.x-environment-badge:hover {
  opacity: 0.2;
}

.x-environment-badge_production {
  background-color: #e53935;
}

.x-environment-badge_staging {
  background-color: #2196f3;
}
